@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i&display=swap);
body {
  height: 100% !important;
  margin: 0px !important;
  font-family: "Ubuntu" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
#root {
  height: 100% !important;
  box-sizing: border-box !important;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.mainContainer {
  margin: 100px 37px 60px 37px;
}

.fullWidthMax300 {
  margin: 10px;
  width: 100%;
  max-width: 300px;
}

.fullWidthMax400 {
  margin: 10px;
  width: 100%;
  max-width: 400px;
}

.table-row > td {
  padding: 8px 56px 8px 24px;
  border: 1px solid #ebe9ee;
}

.width200px {
  width: 200px;
}

.fab {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.chipAsset {
  margin: 2.5px;
}

.scanned {
  background-color: #41b800 !important;
  color: white;
}

.fullWidth {
  width: 98%;
  margin: 12px;
  z-index: 1;
}

#asset-list-url {
  width: 400px;
}

.table-row {
  cursor: pointer;
  border-bottom: 1px solid #ebe9ee;
}

.table-row:hover {
  background-color: #c7e9ff;
}

.margin-5 {
  margin: 8px;
}

.tag-chip {
  margin: 3px;
}

.dropdown {
  width: 200px;
}

.suggestion-item,
.suggestion-item--active {
  white-space: nowrap;
}

.margin-10 {
  margin: 10px;
}

.DateInput {
  height: 38px;
  font-weight: 400;
}

.SingleDatePickerInput > .DateInput {
  width: 168px !important;
}

#start-date-picker {
  font-family: "Ubuntu" !important;
  height: 100% !important;
  font-weight: 400;
}

#end-date-picker {
  font-family: "Ubuntu" !important;
  height: 100% !important;
  font-weight: 400;
}

#single-picker {
  font-family: "Ubuntu" !important;
  height: 100% !important;
  font-weight: 400;
}

.loginComponent {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}

#start-date-picker {
  font-weight: 300;
}

#end-date-picker {
  font-weight: 300;
}

.DateInput_input__focused {
  border-bottom-color: #439fd9 !important;
}

.CalendarDay__selected_span {
  background-color: #7dd0ff !important;
  border: 1px double #7dd0ff !important;
}
.CalendarDay__selected_span:hover {
  background-color: #439fd9 !important;
  border: 1px double #439fd9 !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: #439fd9 !important;
  border: 1px double #439fd9 !important;
}

path.arc {
  opacity: 0.9;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.tooltip {
  position: absolute;
  display: none;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
  box-shadow: -3px 3px 15px #888;
  color: white;
  padding: 6px;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.online-banner {
  position: fixed;
  bottom: 0px;
  color: white;
  background-color: #ff5252;
}

