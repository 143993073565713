@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i&display=swap");

body {
  height: 100% !important;
  margin: 0px !important;
  font-family: "Ubuntu" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
#root {
  height: 100% !important;
  box-sizing: border-box !important;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.mainContainer {
  margin: 100px 37px 60px 37px;
}

.fullWidthMax300 {
  margin: 10px;
  width: 100%;
  max-width: 300px;
}

.fullWidthMax400 {
  margin: 10px;
  width: 100%;
  max-width: 400px;
}

.table-row > td {
  padding: 8px 56px 8px 24px;
  border: 1px solid #ebe9ee;
}

.width200px {
  width: 200px;
}

.fab {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.chipAsset {
  margin: 2.5px;
}

.scanned {
  background-color: #41b800 !important;
  color: white;
}

.fullWidth {
  width: 98%;
  margin: 12px;
  z-index: 1;
}

#asset-list-url {
  width: 400px;
}

.table-row {
  cursor: pointer;
  border-bottom: 1px solid #ebe9ee;
}

.table-row:hover {
  background-color: #c7e9ff;
}

.margin-5 {
  margin: 8px;
}

.tag-chip {
  margin: 3px;
}

.dropdown {
  width: 200px;
}

.suggestion-item,
.suggestion-item--active {
  white-space: nowrap;
}

.margin-10 {
  margin: 10px;
}

.DateInput {
  height: 38px;
  font-weight: 400;
}

.SingleDatePickerInput > .DateInput {
  width: 168px !important;
}

#start-date-picker {
  font-family: "Ubuntu" !important;
  height: 100% !important;
  font-weight: 400;
}

#end-date-picker {
  font-family: "Ubuntu" !important;
  height: 100% !important;
  font-weight: 400;
}

#single-picker {
  font-family: "Ubuntu" !important;
  height: 100% !important;
  font-weight: 400;
}
