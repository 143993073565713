#start-date-picker {
  font-weight: 300;
}

#end-date-picker {
  font-weight: 300;
}

.DateInput_input__focused {
  border-bottom-color: #439fd9 !important;
}

.CalendarDay__selected_span {
  background-color: #7dd0ff !important;
  border: 1px double #7dd0ff !important;
}
.CalendarDay__selected_span:hover {
  background-color: #439fd9 !important;
  border: 1px double #439fd9 !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: #439fd9 !important;
  border: 1px double #439fd9 !important;
}
